export function get_family_members_data(onec_uids, set_state_callback, process_fam_members_callback = (family_data) => {}) {
    //Сколько uid обработано
    let uids_counter = 0;
    //Сколько всего uid
    const uids = Object.values(onec_uids).length;
    //Список данных для каждого uid`а
    let family_data = [];
    //Включаем анимацию загрузки
    if(uids > 0)
        set_state_callback({loading: true});
    for(let uid of Object.values(onec_uids))
    {
        fetch('/api/getclientinfo', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')}, body: JSON.stringify({ "clientId": uid })})
            .then(response => response.json())
            .then(data => {
                //Выключаем анимацию загрузки
                if(++uids_counter >= uids)
                    set_state_callback({loading: false});

                //Проверяем наличие обязательных полей в ответе
                if(!('result' in data) || !('body' in data))
                {
                    //Устанавливаем ошибку и прекращаем обработку
                    set_state_callback({error_messages: ["Получен некорректный ответ от сервера"]});
                }
                else
                {
                    //Устанавливаем ошибку, если нет поля data
                    if(!data.result)
                    {
                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                        {
                            localStorage.removeItem('accessToken');
                            window.location = "/login";
                        }
                        else
                            set_state_callback({error_messages: ["Получена ошибка от сервера"]});
                    }
                    else
                    {
                        let name = data.body.lname + " " + data.body.fname + " " + data.body.sname;
                        family_data.push({name: name, birthdate: data.body.dob, uid: uid, onec: data.body});
                    }

                    if(uids_counter >= uids)
                    {
                        //Получаем бонусы
                        uids_counter = 0;
                        set_state_callback({loading: true});

                        //Список данных для каждого uid`а
                        let bonuses_data = [];
                        for(let uid of Object.values(onec_uids))
                        {
                            fetch('/api/getclientbonusinfo', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')}, body: JSON.stringify({ "clientId": uid })})
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки
                                    if(++uids_counter >= uids)
                                        set_state_callback({loading: false});

                                    //Проверяем наличие обязательных полей в ответе
                                    if(!('result' in data) || !('body' in data))
                                    {
                                        //Устанавливаем ошибку и прекращаем обработку
                                        set_state_callback({error_messages: ["Получен некорректный ответ от сервера"]});
                                    }
                                    else
                                    {
                                        //Устанавливаем ошибку, если нет поля data
                                        if(!data.result)
                                        {
                                            //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                            if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                            {
                                                localStorage.removeItem('accessToken');
                                                window.location = "/login";
                                            }
                                        }
                                        else
                                            bonuses_data.push(data.body);

                                        if(uids_counter >= uids)
                                        {
                                            //Сохраняем список данных членов семьи
                                            set_state_callback({ family_data: family_data, bonuses_data: bonuses_data });
                                            //Вызываем callback-обработчик
                                            process_fam_members_callback(family_data);
                                        }
                                    }
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки
                                    if(++uids_counter >= uids)
                                        set_state_callback({loading: false});
                                    //Устанавливаем ошибку
                                    set_state_callback({error_messages: ["Ошибка получения ответа от сервера"]});
                                });
                        }
                    }
                }
            })
            .catch((error) => {
                //Выключаем анимацию загрузки
                if(++uids_counter >= uids)
                    set_state_callback({loading: false});
                //Устанавливаем ошибку
                set_state_callback({error_messages: ["Ошибка получения ответа от сервера"]});
            });
    }
}

export function get_user_info(validated_callback, set_state_callback) {
    //Закрываем окно выбора 1C
    set_state_callback({ open_choose_onec_modal: false });

    //Включаем анимацию загрузки
    set_state_callback({loading: true});

    //Запрашиваем статус
    fetch('/api/getcabuserinfo', {method: 'GET', headers:{'ZR-Access-Token': localStorage.getItem('accessToken')}})
        .then(response => response.json())
        .then(data => {
            //Выключаем анимацию загрузки
            set_state_callback({loading: false});

            //Проверяем наличие обязательных полей в ответе
            if(!('result' in data) || !('body' in data))
            {
                //Устанавливаем ошибку и прекращаем обработку
                set_state_callback({error_messages: ["Получен некорректный ответ от сервера"]});
                return;
            }

            //Устанавливаем ошибку, если нет поля data
            if(!data.result)
            {
                //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                {
                    localStorage.removeItem('accessToken');
                    window.location = "/login";
                }
                else
                    set_state_callback({error_messages: ["Получена ошибка от сервера"]});
            }
            else
            {
                //Вытягиваем данные пользователя
                let cabuserinfo = data.body;
                set_state_callback({cabuserinfo: cabuserinfo});

                //Запрашиваем данные членов семьи
                get_family_members_data(data.body.onec_uids, set_state_callback, (family_data) => {
                    //Если у клиента есть 1С, запрашиваем данные из него
                    if(data.body.onecowner && data.body.onecowner.length)
                    {
                        //Если пользователь не завершил регистрацию, перенаправляем его на соответствующую страницу
                        if((data.body.fname === undefined || data.body.fname.length === 0) || (data.body.lname === undefined || data.body.lname.length === 0) ||
                            (data.body.sname === undefined || data.body.sname.length === 0) || (data.body.email === undefined || data.body.email.length === 0))
                            window.location = "/register/?section=data";

                        //Включаем анимацию загрузки
                        set_state_callback({loading: true});

                        fetch('/api/getclientinfo', {method: 'POST', headers:{'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken')}, body: JSON.stringify({ "clientId": data.body.onecowner })})
                            .then(response => response.json())
                            .then(data => {
                                //Выключаем анимацию загрузки
                                set_state_callback({loading: false});

                                //Проверяем наличие обязательных полей в ответе
                                if(!('result' in data) || !('body' in data))
                                {
                                    //Устанавливаем ошибку и прекращаем обработку
                                    set_state_callback({error_messages: ["Получен некорректный ответ от сервера"]});
                                    return;
                                }

                                //Устанавливаем ошибку, если нет поля data
                                if(!data.result)
                                {
                                    //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                    if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                    {
                                        localStorage.removeItem('accessToken');
                                        window.location = "/login";
                                    }
                                    else
                                        set_state_callback({error_messages: ["Получена ошибка от сервера"]});
                                }
                                else
                                {
                                    //Если пользователь не завершил регистрацию, перенаправляем его на соответствующую страницу
                                    if(data.body.dob === undefined || data.body.dob.length === 0)
                                        window.location = "/register/?section=data";

                                    //Вызываем callback для установки флага валидации пользователя
                                    validated_callback(data.body.vrf != 1);

                                    //Вытягиваем данные пользователя
                                    set_state_callback({ onec: data.body });
                                }
                            })
                            .catch((error) => {
                                //Выключаем анимацию загрузки
                                set_state_callback({loading: false});
                                //Устанавливаем ошибку
                                set_state_callback({error_messages: ["Ошибка получения ответа от сервера"]});
                            });
                    }
                    //Если у клиента нет 1C
                    else
                    {
                        //Если среди членов семьи есть 1C, открываем окно выбора 1С
                        if(data.body.onec_uids && Object.values(data.body.onec_uids).length && (family_data === undefined || family_data.filter((data) => new Date().getFullYear() - data.birthdate.split('.')[2] > 15).length))
                            set_state_callback({ open_choose_onec_modal: true });
                        //Если вообще нет 1C, отправляем запрос на создание
                        else
                        {
                            //Если пользователь не завершил регистрацию, перенаправляем его на соответствующую страницу
                            if((data.body.fname === undefined || data.body.fname.length === 0) || (data.body.lname === undefined || data.body.lname.length === 0) ||
                                (data.body.sname === undefined || data.body.sname.length === 0) || (data.body.email === undefined || data.body.email.length === 0))
                                window.location = "/register/?section=data";

                            //Включаем анимацию загрузки
                            set_state_callback({loading: true});

                            //Отправляем данные на сервер
                            const request = {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
                                body: JSON.stringify({ "uid": "", "fname": cabuserinfo.fname ? cabuserinfo.fname : "", "lname": cabuserinfo.lname ? cabuserinfo.lname : "",
                                    "sname": cabuserinfo.sname ? cabuserinfo.sname : "", "onecowner": cabuserinfo.onecowner ? cabuserinfo.onecowner : "",
                                    "ids": [], "bdate": "", "sex": "", "tel": "", "telw": "", "lrid": "",
                                    "addrlgstr": "", "addrlg": { "idx": "", "cn": "", "rgt": "", "rg": "", "ds": "", "tnt": "", "tn": "", "stt": "", "st": "", "hs": "", "cp": "", "ft": "" },
                                    "addrfcstr": "", "addrfc": { "idx": "", "cn": "", "rgt": "", "rg": "", "ds": "", "tnt": "", "tn": "", "stt": "", "st": "", "hs": "", "cp": "", "ft": "" },
                                    "isid": "", "adid": "", "respid": "", "cmt": "",
                                    "pass": { "ser": "", "num": "", "udate": "", "ucode": "", "unit": "" }, "snils": "", "vrf": "0"
                                })
                            };
                            fetch('/api/SetUserInfo', request)
                                .then(response => response.json())
                                .then(data => {
                                    //Выключаем анимацию загрузки
                                    set_state_callback({loading: false});

                                    //В случае успеха перезагружаем страницу для обновления данных
                                    if(data.result)
                                        window.location.reload();
                                    else
                                    {
                                        //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                                        if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                                        {
                                            localStorage.removeItem('accessToken');
                                            window.location = "/login";
                                        }
                                        else
                                            set_state_callback({error_messages: ["Получена ошибка от сервера"]});
                                    }
                                })
                                .catch((error) => {
                                    //Выключаем анимацию загрузки
                                    set_state_callback({loading: false});
                                    //Устанавливаем ошибку
                                    set_state_callback({error_messages: ["Ошибка получения ответа от сервера"]});
                                });
                        }
                    }
                });

            }
        })
        .catch((error) => {
            //Выключаем анимацию загрузки
            set_state_callback({loading: false});
            //Устанавливаем ошибку
            set_state_callback({error_messages: ["Ошибка получения ответа от сервера"]});
        });
}

function get_values_array(data) {
    let result = [];

    for(let key in data)
        result.push(data[key])

    return result;
}

export function sendOnecSet(onec, cabuserinfo, errors_callback, set_state_callback) {
    //Если у нас нет данных 1с или личного кабинета, выходим
    if(!onec || !cabuserinfo)
        return;

    //Устанавливаем анимацию загрузки
    set_state_callback({ loading: true });

    let errors = []
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
        body: JSON.stringify({ "uid": onec.UID ? onec.UID : "", "fname": onec.fname ? onec.fname : "", "lname": onec.lname ? onec.lname : "", "sname": onec.sname ? onec.sname : "",
                                "email": cabuserinfo.email ? cabuserinfo.email : "", "onecowner": cabuserinfo.onecowner ? cabuserinfo.onecowner : "", "ids": get_values_array(cabuserinfo.onec_uids),
                                "bdate": (onec.dob && onec.dob.length >= 10) ? (onec.dob[6] + onec.dob[7] + onec.dob[8] + onec.dob[9] + onec.dob[3] + onec.dob[4] + onec.dob[0] + onec.dob[1] + "000000") : "",
                                "sex": onec.sex ? onec.sex : "", "tel": onec.tel ? onec.tel.replaceAll("-", "") : "", "telw": onec.telw ? onec.telw : "", "lrid": onec.lrid ? onec.lrid : "",
                                "addrlgstr": onec.addrlgstr ? onec.addrlgstr: "",
                                "addrlg": { "idx": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].idx) ? onec.addrlg[0].idx : "",
                                            "cn": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].cn) ? onec.addrlg[0].cn : "",
                                            "rgt": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].rgt) ? onec.addrlg[0].rgt : "",
                                            "rg": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].rg) ? onec.addrlg[0].rg : "",
                                            "ds": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].ds) ? onec.addrlg[0].ds : "",
                                            "tnt": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].tnt) ? onec.addrlg[0].tnt : "",
                                            "tn": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].tn) ? onec.addrlg[0].tn : "",
                                            "stt": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].stt) ? onec.addrlg[0].stt : "",
                                            "st": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].st) ? onec.addrlg[0].st : "",
                                            "hs": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].hs) ? onec.addrlg[0].hs : "",
                                            "cp": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].cp) ? onec.addrlg[0].cp : "",
                                            "ft": (onec.addrlg && onec.addrlg.length > 0 && onec.addrlg[0].ft) ? onec.addrlg[0].ft : ""
                                          },
                                "addrfcstr": onec.addrfcstr ? onec.addrfcstr : "",
                                "addrfc": { "idx": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].idx) ? onec.addrfc[0].idx : "",
                                            "cn": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].cn) ? onec.addrfc[0].cn : "",
                                            "rgt": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].rgt) ? onec.addrfc[0].rgt : "",
                                            "rg": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].rg) ? onec.addrfc[0].rg : "",
                                            "ds": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].ds) ? onec.addrfc[0].ds : "",
                                            "tnt": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].tnt) ? onec.addrfc[0].tnt : "",
                                            "tn": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].tn) ? onec.addrfc[0].tn : "",
                                            "stt": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].stt) ? onec.addrfc[0].stt : "",
                                            "st": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].st) ? onec.addrfc[0].st : "",
                                            "hs": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].hs) ? onec.addrfc[0].hs : "",
                                            "cp": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].cp) ? onec.addrfc[0].cp : "",
                                            "ft": (onec.addrfc && onec.addrfc.length > 0 && onec.addrfc[0].ft) ? onec.addrfc[0].ft : ""
                                          },
                                "isid": onec.isource ? onec.isource : "", "adid": onec.adid ? onec.adid : "", "respid": onec.respid ? onec.respid : "", "cmt": onec.cmt ? onec.cmt : "",
                                "pass": { "ser": (onec.pass && onec.pass.length > 0 && onec.pass[0].ser) ? onec.pass[0].ser : "",
                                          "num": (onec.pass && onec.pass.length > 0 && onec.pass[0].num) ? onec.pass[0].num : "",
                                          "udate": (onec.pass && onec.pass.length > 0 && onec.pass[0].udate && onec.pass[0].udate.length >= 10) ?
                                              (onec.pass[0].udate[0] + onec.pass[0].udate[1] + onec.pass[0].udate[2] +
                                               onec.pass[0].udate[3] + onec.pass[0].udate[5] + onec.pass[0].udate[6] +
                                               onec.pass[0].udate[8] + onec.pass[0].udate[9] + "000000") : "",
                                          "ucode": (onec.pass && onec.pass.length > 0 && onec.pass[0].ucode) ? onec.pass[0].ucode : "",
                                          "unit": (onec.pass && onec.pass.length > 0 && onec.pass[0].unit) ? onec.pass[0].unit : ""
                                        }, "snils": onec.snils ? onec.snils : "", "vrf": onec.vrf ? onec.vrf: "0"
                            })
    };
    fetch('/api/SetUserInfo', request)
        .then(response => response.json())
        .then(data => {
            //Сбрасываем анимацию загрузки
            set_state_callback({ loading: false });

            //Проверяем наличие обязательных полей в ответе
            if(!('result' in data) || !('body' in data))
                return;

            //В случае ошибки добавляем ее в состояние
            if(!data.result)
            {
                //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                {
                    localStorage.removeItem('accessToken');
                    window.location = "/login";
                }
                else
                    //Добавляем ошибки в состояние через callback
                    errors_callback([...errors, data.body.errorAns]);
            }
            else
            {
                //Сбрасываем ошибки в состояние через callback
                errors_callback([]);

                //Переспрашиваем и обрабатываем данные пользователя
                get_user_info(() => {}, set_state_callback);
            }
        })
        .catch((error) => {
            errors_callback([...errors, "Ошибка получения данных от сервера"]);
            set_state_callback({ loading: false });
        });
}

export function sendOnecSetByFamilyId(cabuserinfo, family_data, index, errors_callback, set_state_callback) {
    //Устанавливаем анимацию загрузки
    set_state_callback({ loading: true });

    let errors = []
    const request = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'ZR-Access-Token': localStorage.getItem('accessToken') },
        body: JSON.stringify({ "uid": family_data[index].onec.UID, "fname": family_data[index].onec.fname,
                                "lname": family_data[index].onec.lname, "sname": family_data[index].onec.sname, "email": cabuserinfo.email ? cabuserinfo.email : "",
                                "onecowner": family_data[index].onec.UID, "ids": get_values_array(cabuserinfo.onec_uids),
                                "bdate": (family_data[index].onec.dob && family_data[index].onec.dob.length >= 10) ?
                                            (family_data[index].onec.dob[6] + family_data[index].onec.dob[7] +
                                             family_data[index].onec.dob[8] + family_data[index].onec.dob[9] +
                                             family_data[index].onec.dob[3] + family_data[index].onec.dob[4] +
                                             family_data[index].onec.dob[0] + family_data[index].onec.dob[1] + "000000") : "",
                                "sex": family_data[index].onec.sex, "tel": family_data[index].onec.tel.replaceAll("-", ""),
                                "telw": family_data[index].onec.telw, "lrid": family_data[index].onec.lrid,
                                "addrlgstr": family_data[index].onec.addrlgstr,
                                "addrlg": { "idx": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].idx) ? family_data[index].onec.addrlg[0].idx : "",
                                            "cn": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].cn) ? family_data[index].onec.addrlg[0].cn : "",
                                            "rgt": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].rgt) ? family_data[index].onec.addrlg[0].rgt : "",
                                            "rg": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].rg) ? family_data[index].onec.addrlg[0].rg : "",
                                            "ds": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].ds) ? family_data[index].onec.addrlg[0].ds : "",
                                            "tnt": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].tnt) ? family_data[index].onec.addrlg[0].tnt : "",
                                            "tn": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].tn) ? family_data[index].onec.addrlg[0].tn : "",
                                            "stt": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].stt) ? family_data[index].onec.addrlg[0].stt : "",
                                            "st": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].st) ? family_data[index].onec.addrlg[0].st : "",
                                            "hs": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].hs) ? family_data[index].onec.addrlg[0].hs : "",
                                            "cp": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].cp) ? family_data[index].onec.addrlg[0].cp : "",
                                            "ft": (family_data[index].onec.addrlg && family_data[index].onec.addrlg.length > 0 && family_data[index].onec.addrlg[0].ft) ? family_data[index].onec.addrlg[0].ft : ""
                                          },
                                "addrfcstr": family_data[index].onec.addrfcstr,
                                "addrfc": { "idx": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].idx) ? family_data[index].onec.addrfc[0].idx : "",
                                            "cn": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].cn) ? family_data[index].onec.addrfc[0].cn : "",
                                            "rgt": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].rgt) ? family_data[index].onec.addrfc[0].rgt : "",
                                            "rg": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].rg) ? family_data[index].onec.addrfc[0].rg : "",
                                            "ds": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].ds) ? family_data[index].onec.addrfc[0].ds : "",
                                            "tnt": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].tnt) ? family_data[index].onec.addrfc[0].tnt : "",
                                            "tn": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].tn) ? family_data[index].onec.addrfc[0].tn : "",
                                            "stt": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].stt) ? family_data[index].onec.addrfc[0].stt : "",
                                            "st": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].st) ? family_data[index].onec.addrfc[0].st : "",
                                            "hs": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].hs) ? family_data[index].onec.addrfc[0].hs : "",
                                            "cp": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].cp) ? family_data[index].onec.addrfc[0].cp : "",
                                            "ft": (family_data[index].onec.addrfc && family_data[index].onec.addrfc.length > 0 && family_data[index].onec.addrfc[0].ft) ? family_data[index].onec.addrfc[0].ft : ""
                                          },
                                "isid": family_data[index].onec.isource, "adid": family_data[index].onec.adid,
                                "respid": family_data[index].onec.respid, "cmt": family_data[index].onec.cmt,
                                "pass": { "ser": (family_data[index].onec.pass && family_data[index].onec.pass.length > 0 && family_data[index].onec.pass[0].ser) ? family_data[index].onec.pass[0].ser : "",
                                          "num": (family_data[index].onec.pass && family_data[index].onec.pass.length > 0 && family_data[index].onec.pass[0].num) ? family_data[index].onec.pass[0].num : "",
                                          "udate": (family_data[index].onec.pass && family_data[index].onec.pass.length > 0 && family_data[index].onec.pass[0].udate && family_data[index].onec.pass[0].udate.length >= 10) ?
                                              (family_data[index].onec.pass[0].udate[0] + family_data[index].onec.pass[0].udate[1] + family_data[index].onec.pass[0].udate[2] +
                                               family_data[index].onec.pass[0].udate[3] + family_data[index].onec.pass[0].udate[5] + family_data[index].onec.pass[0].udate[6] +
                                               family_data[index].onec.pass[0].udate[8] + family_data[index].onec.pass[0].udate[9] + "000000") : "",
                                          "ucode": (family_data[index].onec.pass && family_data[index].onec.pass.length > 0 && family_data[index].onec.pass[0].ucode) ? family_data[index].onec.pass[0].ucode : "",
                                          "unit": (family_data[index].onec.pass && family_data[index].onec.pass.length > 0 && family_data[index].onec.pass[0].unit) ? family_data[index].onec.pass[0].unit : ""
                                        }, "snils": family_data[index].onec.snils ? family_data[index].onec.snils : "", "vrf": family_data[index].onec.vrf ? family_data[index].onec.vrf: "0"
                            })
    };
    fetch('/api/SetUserInfo', request)
        .then(response => response.json())
        .then(data => {
            //Сбрасываем анимацию загрузки
            set_state_callback({ loading: false });

            //Проверяем наличие обязательных полей в ответе
            if(!('result' in data) || !('body' in data))
                return;

            //В случае ошибки добавляем ее в состояние
            if(!data.result)
            {
                //Если токен недействителен, удаляем его и перенаправляем на страницу входа
                if('errorAns' in data.body && data.body.errorAns === "Данный токен не действителен.")
                {
                    localStorage.removeItem('accessToken');
                    window.location = "/login";
                }
                else
                    //Добавляем ошибки в состояние через callback
                    errors_callback([...errors, data.body.errorAns]);
            }
            else
            {
                //Сбрасываем ошибки в состояние через callback
                errors_callback([]);

                //Переспрашиваем и обрабатываем данные пользователя
                get_user_info(() => {}, set_state_callback);
            }
        })
        .catch((error) => {
            errors_callback([...errors, "Ошибка получения данных от сервера"]);
            set_state_callback({ loading: false });
        });
}
