import { useState, useEffect, useRef } from 'react';

export default function SnilsInput({snils = "", setSnils = (value) => {}, name = "snils-num", class_name="form-input", reset_error_callback = () => {}}) {
    //Позиция выделения (курсора)
    const [selectionStart, setSelectionStart] = useState(0);
    const [selectionEnd, setSelectionEnd] = useState(0);
    //Наличие фокуса на поле ввода
    const [inputFocused, setInputFocused] = useState(false);
    //Ссылка на поле ввода
    const textInput = useRef(null);
    //Используется ли в качестве разделителя пробел
    const [isSpace, setIsSpace] = useState(true);

    //Восстановление позиции курсора при обновлении состояния
    /* eslint-disable */
    useEffect(() => {
        //Если элемент не в фокусе, выходим
        if(!inputFocused)
            return;

        //Устанавливаем курсор в правильную позицию внутри элемента
        let element = document.querySelector("#" + name);
        if(element !== null)
            element.setSelectionRange(selectionStart, selectionEnd);
    });
    /* eslint-enable */

    const formSnils = (str = snils, is_space = isSpace) => {
        //Если снилс пуст и поле не в фокусе, возвращаем пустую строку
        if(str.length === 0 && !inputFocused)
            return "";

        //Удаляем нечисловые символы
        let str_copy = str;
        str = "";
        for(let i = 0; i < str_copy.length; i++)
        {
            if(str_copy[i] >= '0' && str_copy[i] <= '9')
                str = str + str_copy[i];
        }

        //Подготавливаем шаблон номера
        let template = "";

        //Обрабатываем первую тройку символов
        for(let i = 0; i < 3; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        //Обрабатываем вторую тройку символов
        template += "-"
        for(let i = 3; i < 6; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        //Обрабатываем третью тройку символов
        template += "-"
        for(let i = 6; i < 9; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        //Обрабатываем пару символов
        template += is_space ? " " : "-";
        for(let i = 9; i < 11; i++)
            if(i < str.length)
                template += str[i];
            else
                template += '_';

        return template;
    }

    const getLastDigitPosition = (str) => {
        let last_digit_position = -1;
        for(let i = str.length - 1; i >= 0; i--) {
          if (/\d/.test(str[i])) {
            last_digit_position = i;
            break;
          }
        }
        return last_digit_position;
    }

    //Обработчик изменения поля ввода
    const inputHandler = (e) => {
        //Получаем значение от пользователя
        let result = e.target.value;

        //Получаем позицию курсора
        let selection_start = e.target.selectionStart;
        let selection_end = e.target.selectionEnd;

        //Игнорируем данные, длиннее ожидаемых
        if(result.replace(/\D/g, '').length > 11)
        {
            //Сохраняем позицию курсора и выходим
            setSelectionStart(selection_start);
            setSelectionEnd(selection_end);
            return;
        }

        //Удаляем нечисловые символы
        let result_copy = result;
        result = "";
        let is_space = isSpace;
        for(let i = 0; i < result_copy.length; i++)
        {
            //Помечаем тип разделителя, если он указан
            if(i == 11)
            {
                if(result_copy[i] == '-')
                {
                    is_space = false;
                    setIsSpace(false);
                }
                else if(result_copy[i] == ' ')
                {
                    is_space = true;
                    setIsSpace(true);
                }
            }
            //Переносим числовые символы
            if(result_copy[i] >= '0' && result_copy[i] <= '9')
                result = result + result_copy[i];
        }

        //Обрезаем лишние символы
        result = result.slice(0, 11);

        //Если данные вводятся, а не стираются
        if(e.target.value.length > formSnils().length)
        {
            //Пропускаем служебные символы
            if(selection_start === 3 || selection_start === 7)
                selection_start += 1;
            if(selection_end === 3 || selection_end === 7)
                selection_end += 1;

            //Переносим курсор к последнему числу в строке
            let last_digit_position = getLastDigitPosition(formSnils(result)) + 1;
            if(selection_start > last_digit_position)
                selection_start = last_digit_position == 11 ? 12 : last_digit_position;
            if(selection_end > last_digit_position)
                selection_end = last_digit_position == 11 ? 12 : last_digit_position;

            if(selection_start === 3 || selection_start === 7)
                selection_start += 1;
            if(selection_end === 3 || selection_end === 7)
                selection_end += 1;
        }
        else
        {
            if(selection_start === 4 || selection_start === 8)
                selection_start -= 1;
            if(selection_end === 4 || selection_end === 8)
                selection_end -= 1;
        }

        //Обновляем состояние
        setSnils(formSnils(result, is_space));
        setSelectionStart(selection_start);
        setSelectionEnd(selection_end);
        //Вызываем callback для сброса ошибки
        reset_error_callback();
    }

    return (
        <input id={name} name={name} className={class_name} ref={textInput} type="text" placeholder="Введите номер СНИЛС" value={formSnils(snils)} onChange={inputHandler}
                onFocus={(e) => setInputFocused(true)} onBlur={(e) => setInputFocused(false)}></input>
    );

}
